const config = {	

     /* localhost */
    // API_BASE_URL : 'http://localhost/sendgreetings/apis/',  
	// baseurl : 'http://localhost:3000/',
    // API_Video_URL : 'http://localhost:3000/', 
    // Login_Url: 'http://localhost:3000/login'

	
	/* eways */
	 baseurl : 'https://esendgreetings.com/',
     API_BASE_URL : 'https://esendgreetings.com/apis/', 
     API_Video_URL : 'https://esendgreetings.com/',
     Login_Url: 'https://esendgreetings.com/login' 
	
	/* 1800giftportal.in */
	//  baseurl : 'https://1800giftportal.in/videomessage/',
    //  API_BASE_URL : 'https://1800giftportal.in/videomessage/apis/', 
    //  API_Video_URL : 'https://1800giftportal.in/videomessage/',
    //  Login_Url: 'https://1800giftportal.in/videomessage/login' 
     
    }
export default config;
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import config from '../../config'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Authtoken from '../../permissions/GetAuthToken';
import { BsCalendar2Date, BsCheck2 } from "react-icons/bs";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import loadingtrash from '../../loader/loader.gif';
import { IoIosPlayCircle } from "react-icons/io";
import { IconContext } from "react-icons";
import { ImEye } from "react-icons/im";
import { useSearchParams } from 'react-router-dom';

export default function Reports() {
    const [searchParams] = useSearchParams();
    const [gridApi, setGridApi] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [StartDate, setStartDate] = useState((searchParams.get('startdate')) ? searchParams.get('startdate') : '');
    const [endDate, setendDate] = useState((searchParams.get('enddate')) ? searchParams.get('enddate') : '');
    const [loader, setLoader] = useState(false);
    const [videopop, setvideopop] = useState('');
    const [RespVideoAdmin, setRespVideoAdmin] = useState([]);
    const [RespVideoTeammember, setRespVideoTeammember] = useState([]);
    const [RespVideoDeveloper, setRespVideoDeveloper] = useState([]);
    const [RespCustomerRole, setRespCustomerRole] = useState('');
    const [displayStartDate, setdisplayStartDate] = useState('');
    const [displayEndDate, setdisplayEndDate] = useState('');

    useEffect(() => {
        if(StartDate){
            let StartDateArray = StartDate.split('-')
            setdisplayStartDate(StartDateArray[2]+'-'+StartDateArray[1]+'-'+StartDateArray[0])
        }
        
    }, [StartDate])


    useEffect(() => {
        if(endDate){
            let endDateeArray = endDate.split('-')
            setdisplayEndDate(endDateeArray[2]+'-'+endDateeArray[1]+'-'+endDateeArray[0])
        }
        
    }, [endDate])

    //const [dateUrl, setdateUrl] = useState('?startdate=&enddate=');
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    ]);

    const onGridReady = (params) => {
        setGridApi(params.api)
    }

    useEffect(() => {
        getvideos()
    }, [])

    function hadnlepopclose() {
        setvideopop('')
    }

    function handleVideo(props) {
        setvideopop(config.API_Video_URL + 'output/' + props.data.ParentNo + '/' + props.data.CustomerNo + '/' + props.data.VideoName)
    };

    const VideoPopup = props =>
        <button type="button" onClick={() => handleVideo(props)} className='btnpopp' data-bs-toggle="modal" data-bs-target="#exampleModal">
            <IoIosPlayCircle />
        </button>;
    
    function setdaterange(data) {
        setState([data])
        let startday = data.startDate.getDate()
        let startmonth = data.startDate.getMonth() + 1
        let startyear = data.startDate.getFullYear()
        if (parseInt(startday) < 10) {
            startday = '0' + startday
        }
        if (parseInt(startmonth) < 10) {
            startmonth = '0' + startmonth
        }
        let startdate = startyear + '-' + startmonth + '-' + startday
        let endday = data.endDate.getDate()
        let endmonth = data.endDate.getMonth() + 1
        let endyear = data.endDate.getFullYear()
        if (parseInt(endday) < 10) {
            endday = '0' + endday
        }
        if (parseInt(endmonth) < 10) {
            endmonth = '0' + endmonth
        }
        let enddate = endyear + '-' + endmonth + '-' + endday
        setStartDate(startdate)
        setendDate(enddate)

    }

    function redirectToTeammember(e,teammeberid){
        e.preventDefault();
        let url = config.baseurl+'teammemberreports/'+teammeberid+'/?startdate='+StartDate+'&enddate='+endDate
        window.location.href = url;
    }

    function redirectToAdmin(e,adminid){
        e.preventDefault();
        let url = config.baseurl+'adminreports/'+adminid+'/?startdate='+StartDate+'&enddate='+endDate
        window.location.href = url;
    }

    const columnDefsAdmin = [
        {
            headerName: '',
            cellClass: ["ag-cell-pencileditico"],
            cellRenderer: (data) => {
                return <>
                    <a className="View_btn" onClick = {(e)=>redirectToTeammember(e,data.data.CustomerId)}>
                        <IconContext.Provider
                            value={{ color: 'gray' }}
                        >
                            <div className='userpencilicon'>
                                <ImEye />
                            </div>
                        </IconContext.Provider>
                    </a>
                </>

            },
            colId: 'action',
            editable: false,
            width: 50,
            resizable: true
        },
        { field: 'CustomerName', sortable: true, filter: true, resizable: true, headerName: 'Team Member' },
        { field: 'totalvideos', sortable: true, filter: true, resizable: true, headerName: 'Video Count' },
        { field: 'Total', sortable: true, filter: true, resizable: true, headerName: 'Total Price',
            cellRenderer: (params) => {
                return `₹${params.value}`;
              }
         },
    ]

    const columnDefsTeamMember = [
        { field: 'OrderNo', sortable: true, filter: true, resizable: true, },
        { field: 'createddate', width: '150px',headerName:'Created At', filter: true},
        { field: 'admin', sortable: true, filter: true, resizable: true, },
        { field: 'teammeber', sortable: true, filter: true, resizable: true, headerName: 'Team Member' },
        { field: 'RecieverName',filter: true },
        { field: 'SenderName',filter: true },
        { field: 'TemplateName',filter: true },
        { field: 'VideoName',filter: true },
        
        {
            field: '', width: '80px', cellRenderer: VideoPopup,
        }
    ]

    const columnDefsDeveloper = [
        {
            headerName: '',
            cellClass: ["ag-cell-pencileditico"],
            cellRenderer: (data) => {
                return <>
                    <a className="View_btn" onClick = {(e)=>redirectToAdmin(e,data.data.CustomerId)}>
                        <IconContext.Provider
                            value={{ color: 'gray' }}
                        >
                            <div className='userpencilicon'>
                                <ImEye />
                            </div>
                        </IconContext.Provider>
                    </a>
                </>

            },
            colId: 'action',
            editable: false,
            width: 50,
            resizable: true
        },
        { field: 'CustomerName', sortable: true, filter: true, resizable: true, headerName: 'Customers' },
        { field: 'totalvideos', sortable: true, filter: true, resizable: true, headerName: 'Video Count' },
        { field: 'Total', sortable: true, filter: true, resizable: true, headerName: 'Total Price',
            cellRenderer: (params) => {
                return `₹${params.value}`;
              }
         },
    ]

    function getvideos() {
        let token = Authtoken()
        var newFromdata = new FormData();
        newFromdata.append('token', Authtoken());
        newFromdata.append('StartDate', StartDate)
        newFromdata.append('endDate', endDate);
        setLoader(true)
        let url = config.API_BASE_URL + "reports/index.php"
        fetch(url, {
            method: 'POST',
            headers: {
                //authorization: Authtoken(),
            },
            body: newFromdata
        }).then(response => {
            return response.json();  // <---- this is important
        }).then(response => {
            if (response.CODE == 200) {
                if (response.customerrole == 2) {
                    setRespVideoAdmin(response.data)
                } else if (response.customerrole == 3) {
                    setRespVideoTeammember(response.data)
                }
                else {
                    setRespVideoDeveloper(response.data)
                }
                setRespCustomerRole(response.customerrole)
            }
            setIsOpen(false)
            setLoader(false)
        });
    }

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    function overlayout() {
        setIsOpen(false)
    }

    

    const formatDate = (date) => {
        return date.toDateString();
    };

    return (
        <>
            {(loader) ? <p className='loadingtrash'><img src={loadingtrash} /></p> : ''}
            <div className='customcont'>

                <div onClick={hadnlepopclose} className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                {(videopop) ?
                                    <video autoPlay controls id="myVideo" className='videopopupsec'>
                                        <source src={videopop} type="video/mp4" />
                                    </video>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div onClick={overlayout} className={`calenderoverlay ${isOpen ? 'active2' : ''}`}></div>
                <Header />

                <div className='headingsec'>
                    <div className='headingsecinner'>
                        <h2>Reports</h2>

                        <div className='file filedatecldr'>
                            <p onClick={handleToggle} className='filep'>
                                <span className='datefrmto'>
                                    {StartDate && endDate && (
                                        <p>
                                            {displayStartDate} To {displayEndDate}
                                        </p>
                                    )}
                                </span>
                                <BsCalendar2Date />
                            </p>
                        </div>
                    </div>
                </div>

                <div className={`calendertopcont ${isOpen ? 'active' : ''}`}>
                    <button className='calendericontick' onClick={() => getvideos()} >
                        <BsCheck2 />
                    </button>
                    <DateRangePicker
                        editableDateInputs={true}
                        showMonthArrow={true}
                        retainEndDateOnFirstSelection={false}
                        fixedHeight={true}
                        dragSelectionEnabled={true}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        rangeColors={['#dc0d5b', '#dc0d5b', '#dc0d5b']}
                        onChange={item => setdaterange(item.selection)}
                    />
                </div>


                <div className="ag-theme-alpine" style={{ width: '100%', paddingLeft: 10, paddingRight: 10, height: '76vh' }}>
                    {(RespCustomerRole == 2) ?
                        <AgGridReact
                            rowData={RespVideoAdmin}
                            filter='text'
                            enableColResize={true}
                            animateRows={true}
                            showToolPanel={true}
                            enableSorting={true}
                            pagination={true}
                            onGridReady={onGridReady}
                            paginationPageSize='10'
                            rowSelection={'multiple'}
                            columnDefs={columnDefsAdmin}
                            overlayNoRowsTemplate={
                                '<span className="ag-overlay-loading-center">No Data to Show</span>'
                            }
                        >
                        </AgGridReact>
                        :
                        (RespCustomerRole == 3) ?
                            <AgGridReact
                                rowData={RespVideoTeammember}
                                filter='text'
                                enableColResize={true}
                                animateRows={true}
                                showToolPanel={true}
                                enableSorting={true}
                                pagination={true}
                                onGridReady={onGridReady}
                                paginationPageSize='10'
                                rowSelection={'multiple'}
                                columnDefs={columnDefsTeamMember}
                                overlayNoRowsTemplate={
                                    '<span className="ag-overlay-loading-center">No Data to Show</span>'
                                }
                            >
                            </AgGridReact>
                            :
                            <AgGridReact
                                rowData={RespVideoDeveloper}
                                filter='text'
                                enableColResize={true}
                                animateRows={true}
                                showToolPanel={true}
                                enableSorting={true}
                                pagination={true}
                                onGridReady={onGridReady}
                                paginationPageSize='10'
                                rowSelection={'multiple'}
                                columnDefs={columnDefsDeveloper}
                                overlayNoRowsTemplate={
                                    '<span className="ag-overlay-loading-center">No Data to Show</span>'
                                }
                            >
                            </AgGridReact>
                    }

                </div>

                <Footer />
            </div>
        </>
    )
}

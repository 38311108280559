import React, { useState, useEffect, } from 'react'
import Header from '../../../Header/Header'
import Footer from '../../../Footer/Footer'
import config from '../../../config'
import './Excelvideo.css'
import Authtoken from '../../../permissions/GetAuthToken';
// import loader from '../../../loader/loader.gif'
import loader from '../../../loader/loader.gif'
import { AiOutlineQuestionCircle } from "react-icons/ai";
import PermLoader from '../../../permissions/PermLoader';
import GetPermission from '../../../permissions/PermissionGet';

export default function Evideo() {
	const [errfile, seterrFile] = useState(false);
	const [invalidexcele, setinvalidexcel] = useState(false)
	const [errmsg, seterrmsg] = useState(false);
	const [successmsg, setsuccessmsg] = useState('');
	const [file, setFile] = useState(null);
	const [TemplatePath, setTemplatePath] = useState()
	const [TemplateId, setTemplateId] = useState()
	const [ErrVideos, setErrVideos] = useState('')
	const [Videologo, setVideologo] = useState([])
	const [SelectedvideoLogo, setSelectedvideoLogo] = useState()
	const [Templates, setTemplates] = useState([])
	const [TemplateName, setTemplateName] = useState()
	const [TemplatePrice, setTemplatePrice] = useState()
	const [CoverMessage, setCoverMessage] = useState()

	const [CanCreate, setCanCreate] = useState(false);
	const [PermissionLoader, setPermissionLoader] = useState(true);

	useEffect(() => {
        async function getpermissions(){
            await GetPermission( process.env.REACT_APP_create_Video).then(result => {
                if(result){
                    setCanCreate(true)
                }
            });
            setPermissionLoader(false)
        }
        getpermissions()
    }, []);

	function getTemplateType(e) {
		setTemplatePath(e.target.value)
		let index = e.target.selectedIndex;
	    let optionElement = e.target.childNodes[index]
		let templatenameselected =  optionElement.getAttribute('data-templatename');
		let templateprice=  optionElement.getAttribute('data-templateprice');
		let templateidselected =  optionElement.getAttribute('data-templateid');
		setTemplateName(templatenameselected)
		setTemplatePrice(templateprice)
		setTemplateId(templateidselected)
	}

	function fileSelect(e) {
		seterrFile(false)
		setsuccessmsg(false)
		seterrmsg(false)
		setErrVideos('')
		setFile(e.target.files[0])
	}
	
	function getcovermessage(e){
		setCoverMessage(e.target.value)
	}
	
	function importcsv(e) {
		e.preventDefault();
		seterrmsg(false)
		seterrFile(false)
		setinvalidexcel(false)
		setsuccessmsg(false)
		setErrVideos('');
		let token = Authtoken()
		if (!file) {
			seterrFile(true)
		} else {
			setPermissionLoader(true)
			var newFromdata = new FormData();
			newFromdata.append('exclefile', file);
			newFromdata.append('TemplateId', TemplateId);
			newFromdata.append('TemplatePath', TemplatePath);
			newFromdata.append('TemplateName', TemplateName);
			newFromdata.append('TemplatePrice', TemplatePrice);
			newFromdata.append('SelectedvideoLogo', SelectedvideoLogo);
			newFromdata.append('CoverMessage', CoverMessage);
			newFromdata.append('token', Authtoken());
			
		
			let url = config.API_BASE_URL+"video/create.php"
			fetch(url, {
				method: 'POST',
				headers: {
					authorization: Authtoken(),
				},
				body: newFromdata
			}).then(response => {
				return response.json();  // <---- this is important
			}).then(response => {
				setPermissionLoader(false)
				if (response.CODE === 200) {
					if(response.error.length > 0){
						var errvideosstring = '';
						response.error.map((item)=>{
							errvideosstring = errvideosstring+','+item
						})
						setErrVideos(errvideosstring)
						setsuccessmsg('2')
					}else{
						setsuccessmsg('1')
					}
					
					setFile(null)
					setErrVideos()
				}
				else if (response.CODE == 501) {
					setinvalidexcel(true)
				}
				else {
					seterrmsg(true)
				}
			});
		}
	}

	function getvideologovalue(e) {
        setSelectedvideoLogo(e.target.value)
    }
	
	function getlogos() {
		var newFromdata = new FormData();
		newFromdata.append('token', Authtoken());
        var url = config.API_BASE_URL+"video/getlogos.php"
        fetch(url, {
            method: 'POST',
				// headers: {
				// 	authorization: Authtoken()
				// },
				body: newFromdata
        }).then(response => {
                return response.json();  // <---- this is important
        }).then(async response => {
			if (response.CODE == 200) {
				setVideologo(response.data)
			}else{

			}
            
        });
    }

	function getTemplates() {
		var newFromdata = new FormData();
		newFromdata.append('token', Authtoken());
        var url = config.API_BASE_URL+"video/gettemplates.php"
        fetch(url, {
            method: 'POST',
				// headers: {
				// 	authorization: Authtoken()
				// },
				body: newFromdata
        }).then(response => {
                return response.json();  // <---- this is important
        }).then(async response => {
			if (response.CODE == 200) {
				setTemplates(response.data)
			}else{

			}
            
        });
    }

	useEffect(() => {
		if(CanCreate){
			getlogos()
			getTemplates()
		}        
    }, [CanCreate]);

	return (
		<>

			<div className='customcont'>
				<Header />

				{PermissionLoader ?
					<PermLoader />
					:
					<>
						<div className='headingsec'>
							<div className='headingsecinner'>
								<h2>Create Videos From Excel</h2>
								<div className="errdiv">
									{(invalidexcele) ?
										<p className="errmsg">Invalid File</p>
										: ''
									}
									{(errfile) ?
										<p className="errmsg">please select file</p>
										: ''
									}
									{(errmsg) ?
										<p className="errmsg">Oops!! something went wrong.</p>
										: ''
									}
									{(successmsg == '1') ?
										<p className="successmsg">Video created  successfully..</p>
										: ''
									}
									{(successmsg == '2') ?
										<p className="successmsg">{`Some Video created  successfully. Error in order no ${ErrVideos} videos`} </p>
										: ''
									}
								</div>
							</div>
						</div>
						{(CanCreate) ?
							<> 
								<div className='v-container'>
									<div className='v-containerinner'>
										<div className='v-select '>
											<h2>select Template</h2>
											<select className='form-control' name='name' onChange={(e) => getTemplateType(e)} value={TemplatePath} >
											<option value="" >-- select ---</option>
												{Templates.map((item,ind) => {
													return(
														<option className="d-flex justify-content-start ew-widht-user sh-label" key={ind} data-templatename={item.TemplateName} data-templateprice={item.Price} data-templateid = {item.TemplateId}  value={item.TemplatePath} htmlFor="Create_order">{item.TemplateName} ₹{item.Price}</option>
													)
												}
												
												)}
											</select>
										</div>
										
										<div className="v-select">
										<h2>Select Logo</h2>
											<select className="form-control ewpInline2" name='name' onChange={(e) => getvideologovalue(e)} value={SelectedvideoLogo} >

												<option value="" >-- select ---</option>
												{Videologo.map((item, ind) => {
													return (
															<option className="d-flex justify-content-start ew-widht-user sh-label" key={ind} value={item.LogoVideoPath} htmlFor="Create_order">{item.LogoVideoName} </option>
														
													)
												})}
											</select>
										</div>

										<div className="v-select">
										<h2>Cover Message</h2>
											<input type='text' className="form-control ewpInline2" name='name' onChange={(e) => getcovermessage(e)}  value={CoverMessage}  />
										</div>

										<div className='v-browse'>
											<input type='file' className='form-control' onChange={fileSelect} />
											<button onClick={(e) => importcsv(e)}>Create Video</button>
											<p><AiOutlineQuestionCircle/>
												<span>5 videos can be processed at a time</span>
											</p>
										</div>
									</div>
								</div>
							</>
							:
							<p className = 'unautmessage'>Your are not authorized to view this page.</p>
						}
					</>
				}

				<Footer />
			</div>


		</>
	)
}

import React, { useState, useEffect, } from 'react'
import Header from '../../../Header/Header'
import Footer from '../../../Footer/Footer'
import config from '../../../config'
import './Createvideo.css'
import Authtoken from '../../../permissions/GetAuthToken';
import PermLoader from '../../../permissions/PermLoader';
import GetPermission from '../../../permissions/PermissionGet';

export default function Cvideo() {
	const [successmsg, setsuccessmsg] = useState('');
	const [TemplatePath, setTemplatePath] = useState()
	const [TemplateId, setTemplateId] = useState()
	const [Videologo, setVideologo] = useState([])
	const [SelectedvideoLogo, setSelectedvideoLogo] = useState()
	const [Templates, setTemplates] = useState([])
	const [TemplateName, setTemplateName] = useState()
	const [TemplatePrice, setTemplatePrice] = useState()
	const [CoverMessage, setCoverMessage] = useState()
	const [GiftMessage, setGiftMessage] = useState()
	const [OrderNo, setOrderNo] = useState()

	const [To, setTo] = useState()
	const [From, setFrom] = useState()
	const [Occasion, setOccasion] = useState()

	const [err, seterr] = useState(false);
	const [errmsg, seterrmsg] = useState('');

	const [CanCreate, setCanCreate] = useState(false);

	const [PermissionLoader, setPermissionLoader] = useState(true);

	useEffect(() => {
        async function getpermissions(){
            await GetPermission( process.env.REACT_APP_create_Video).then(result => {
                if(result){
                    setCanCreate(true)
                }
            });
            setPermissionLoader(false)
        }
        getpermissions()
    }, []);


	function getTo(e) {
		setTo(e.target.value)
		seterr(false)
		seterrmsg(false)
	}

	function getFrom(e) {
		setFrom(e.target.value)
		seterr(false)
		seterrmsg(false)
	}

	function getOccasion(e) {
		setOccasion(e.target.value)
		seterr(false)
	}

	function getOrderNo(e) {
		setOrderNo(e.target.value)
		seterr(false)
	}

	function getcovermessage(e) {
		setCoverMessage(e.target.value)
		seterr(false)
	}

	function getGiftMessage(e) {
		setGiftMessage(e.target.value)
		seterr(false)
	}

	function getTemplateType(e) {
		setTemplatePath(e.target.value)
		let index = e.target.selectedIndex;
		let optionElement = e.target.childNodes[index]
		let templatenameselected = optionElement.getAttribute('data-templatename');
		let templateprice = optionElement.getAttribute('data-templateprice');
		let templateidselected = optionElement.getAttribute('data-templateid');
		setTemplateName(templatenameselected)
		setTemplatePrice(templateprice)
		setTemplateId(templateidselected)
	}
	
	function importcsv(e) {
		e.preventDefault();
		seterrmsg(false)
		setsuccessmsg(false)
		if (!TemplateId) {
			seterr(true)
			seterrmsg('Select the template');
			return false
		}
		else if (!SelectedvideoLogo) {
			seterr(true)
			seterrmsg('Select Logo video');
			return false
		} else if (!Occasion) {
			seterr(true)
			seterrmsg('Occasaion is required');
			return false
		}else if (!OrderNo){
			seterr(true)
			seterrmsg('Order  no. is required');
			return false
		}
		else if (!To) {
			seterr(true)
			seterrmsg('To is required');
			return false
		}
		else if (!To) {
			seterr(true)
			seterrmsg('To is required');
			return false
		} else if (!From) {
			seterr(true)
			seterrmsg('From is required');
			return false
		}else if (!GiftMessage) {
			seterr(true)
			seterrmsg('Gift message is required');
			return false
		}

		
		else {
			var newFromdata = new FormData();
			newFromdata.append('TemplateId', TemplateId);
			newFromdata.append('TemplatePath', TemplatePath);
			newFromdata.append('TemplateName', TemplateName);
			newFromdata.append('TemplatePrice', TemplatePrice);
			newFromdata.append('SelectedvideoLogo', SelectedvideoLogo);
			newFromdata.append('CoverMessage', CoverMessage);
			newFromdata.append('token', Authtoken());
			newFromdata.append('Occasion', Occasion);
			newFromdata.append('To', To);
			newFromdata.append('From', From);
			newFromdata.append('GiftMessage', GiftMessage);
			newFromdata.append('OrderNo', OrderNo);
			setPermissionLoader(true)

			let url = config.API_BASE_URL + "video/createform.php"
			fetch(url, {
				method: 'POST',
				headers: {
					authorization: Authtoken(),
				},
				body: newFromdata
			}).then(response => {
				return response.json();  // <---- this is important
			}).then(response => {
				setPermissionLoader(false)
				if (response.CODE === 200) {
					setsuccessmsg('1')
				}

				else {
					seterrmsg(true)
				}
			});
		}
	}

	function getvideologovalue(e) {
		setSelectedvideoLogo(e.target.value)
	}

	function getlogos() {
		var newFromdata = new FormData();
		newFromdata.append('token', Authtoken());
		var url = config.API_BASE_URL + "video/getlogos.php"
		fetch(url, {
			method: 'POST',
			// headers: {
			// 	authorization: Authtoken()
			// },
			body: newFromdata
		}).then(response => {
			return response.json();  // <---- this is important
		}).then(async response => {
			if (response.CODE == 200) {
				setVideologo(response.data)
			} else {

			}

		});
	}

	function getTemplates() {
		var newFromdata = new FormData();
		newFromdata.append('token', Authtoken());
		var url = config.API_BASE_URL + "video/gettemplates.php"
		fetch(url, {
			method: 'POST',
			// headers: {
			// 	authorization: Authtoken()
			// },
			body: newFromdata
		}).then(response => {
			return response.json();  // <---- this is important
		}).then(async response => {
			if (response.CODE == 200) {
				setTemplates(response.data)
			} else {

			}

		});
	}

	useEffect(() => {
		if(CanCreate){
			getlogos()
			getTemplates()
		}		
	}, [CanCreate]);

	return (
		<>
			<div className='customcont'>
				<Header />

				{PermissionLoader ?
					<PermLoader />
					:
					<>
						<div className='headingsec'>
							<div className='headingsecinner'>
								<h2>Create Videos</h2>
								{(CanCreate) ?
								<div className='file'>
									<button onClick={(e) => importcsv(e)}>Create Videos</button>
								</div>
								:
								''
								}
								<div className="errdiv">

									{(err) ?
										<p className="errmsg">{errmsg}</p>
										: ''
									}
									{(successmsg == '1') ?
										<p className="successmsg">Video created successfully..</p>
										: ''
									}

								</div>
							</div>
						</div>
						{(CanCreate) ?
							<> 
							<div className='v-container'>
								<div className='v-containerinner'>
									<div className='v-select '>
										<h2>select Template</h2>
										<select className='form-control' name='name' onChange={(e) => getTemplateType(e)} value={TemplatePath} >
											<option value="" >-- select ---</option>
											{Templates.map((item, ind) => {
												return (
													<option className="d-flex justify-content-start ew-widht-user sh-label" key={ind} data-templatename={item.TemplateName} data-templateprice={item.Price} data-templateid={item.TemplateId} value={item.TemplatePath} htmlFor="Create_order">{item.TemplateName} ₹{item.Price} </option>
												)
											}

											)}
										</select>
									</div>

									<div className="v-select">
										<h2>Select Logo</h2>
										<select className="form-control ewpInline2" name='name' onChange={(e) => getvideologovalue(e)} value={SelectedvideoLogo} >

											<option value="" >-- select ---</option>
											{Videologo.map((item, ind) => {
												return (
													<option className="d-flex justify-content-start ew-widht-user sh-label" key={ind} value={item.LogoVideoPath} htmlFor="Create_order">{item.LogoVideoName} </option>

												)
											})}
										</select>
									</div>

									<div className="v-select">
										<h2>Cover Message</h2>
										<input type='text' className="form-control ewpInline2" name='name' onChange={(e) => getcovermessage(e)} value={CoverMessage} />
									</div>


									<div className="v-Occasion">
										<h2>Occasion</h2>
										<input type='text' className="form-control ewpInline2" name='name' required
											onChange={(e) => getOccasion(e)} value={Occasion} />
									</div>
									<div className="v-To">
										<h2>OrderNo</h2>
										<input type='text' className="form-control ewpInline2" name='name' required
											onChange={(e) => getOrderNo(e)} value={OrderNo} />
									</div>
									<div className="v-To">
										<h2>To</h2>
										<input type='text' className="form-control ewpInline2" name='name' required
											onChange={(e) => getTo(e)} value={To} />
									</div>
									<div className="v-From">
										<h2>From</h2>
										<input type='text' className="form-control ewpInline2" name='name' required
											onChange={(e) => getFrom(e)} value={From} />
									</div>


									<div class="v-Message">
										<h2>Gift Message</h2>
										<textarea class="form-control ewInline2" 
										maxlength="300" 
										rows="5" placeholder='(Maximum 300 words)' required  
										onChange={(e) => getGiftMessage(e)}
										value={GiftMessage}
										/>


									</div>



								</div>
							</div>
							</>
							:
							<p className = 'unautmessage'>Your are not authorized to view this page.</p>
						}						
					</>
				}

				<Footer />
			</div>
		</>
	)
}

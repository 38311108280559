import React, { useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import config from '../../config'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Authtoken from '../../permissions/GetAuthToken';
import { BsCalendar2Date } from "react-icons/bs";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import loadingtrash from '../../loader/loader.gif';
import { useParams } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import { IconContext } from "react-icons";
import { ImEye } from "react-icons/im";

export default function TeamMemberReports() {
    const [gridApi, setGridApi] = useState(null);
    const [loader, setLoader] = useState(false);
    const [videopop, setvideopop] = useState('');
    const [RespVideoTeammember, setRespVideoTeammember] = useState([]);
    const paramas = useParams();
    const id = paramas.id
    const [searchParams] = useSearchParams();
    const StartDate = (searchParams.get('startdate')) ? searchParams.get('startdate') : ''
    const endDate = (searchParams.get('enddate')) ? searchParams.get('enddate') : ''
    const [displayStartDate, setdisplayStartDate] = useState('');
    const [displayEndDate, setdisplayEndDate] = useState('');
    const [BackbtnLink, setBackbtnLink] = useState(config.baseurl + 'reports?startdate=' + StartDate + '&enddate=' + endDate);

    useEffect(() => {
        if (StartDate) {
            let StartDateArray = StartDate.split('-')
            setdisplayStartDate(StartDateArray[2] + '-' + StartDateArray[1] + '-' + StartDateArray[0])
        }

    }, [StartDate])


    useEffect(() => {
        if (endDate) {
            let endDateeArray = endDate.split('-')
            setdisplayEndDate(endDateeArray[2] + '-' + endDateeArray[1] + '-' + endDateeArray[0])
        }

    }, [endDate])



    const onGridReady = (params) => {
        setGridApi(params.api)
    }

    useEffect(() => {
        getvideos()
    }, [])

    function hadnlepopclose() {
        setvideopop('')
    }

    function redirectToTeammember(e,teammeberid){
        e.preventDefault();
        let url = config.baseurl+'teammemberreports/'+teammeberid+'/?startdate='+StartDate+'&enddate='+endDate+'&adminid='+id
        window.location.href = url;
    }

    const columnDefsTeamMember = [
        {
            headerName: '',
            cellClass: ["ag-cell-pencileditico"],
            cellRenderer: (data) => {
                return <>
                    <a className="View_btn" onClick = {(e)=>redirectToTeammember(e,data.data.CustomerId)}>
                        <IconContext.Provider
                            value={{ color: 'gray' }}
                        >
                            <div className='userpencilicon'>
                                <ImEye />
                            </div>
                        </IconContext.Provider>
                    </a>
                </>

            },
            colId: 'action',
            editable: false,
            width: 50,
            resizable: true
        },
        { field: 'CustomerName', sortable: true, filter: true, resizable: true, headerName: 'Team Member' },
        { field: 'totalvideos', sortable: true, filter: true, resizable: true, headerName: 'Video Count' },
        { field: 'Total', sortable: true, filter: true, resizable: true, headerName: 'Total Price',
            cellRenderer: (params) => {
                return `₹${params.value}`;
              }
         },
    ]

    function getvideos() {
        let token = Authtoken()
        var newFromdata = new FormData();
        newFromdata.append('token', Authtoken());
        newFromdata.append('StartDate', StartDate);
        newFromdata.append('endDate', endDate);
        newFromdata.append('id', id);
        setLoader(true)
        let url = config.API_BASE_URL + "reports/adminindex.php"
        fetch(url, {
            method: 'POST',
            body: newFromdata
        }).then(response => {
            return response.json();  // <---- this is important
        }).then(response => {
            if (response.CODE == 200) {
                setRespVideoTeammember(response.data)
            }
            setLoader(false)
        });
    }



    return (
        <>
            {(loader) ? <p className='loadingtrash'><img src={loadingtrash} /></p> : ''}
            <div className='customcont'>

                <div onClick={hadnlepopclose} className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                {(videopop) ?
                                    <video autoPlay controls id="myVideo" className='videopopupsec'>
                                        <source src={videopop} type="video/mp4" />
                                    </video>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
                <div className='headingsec'>
                    <div className='headingsecinner'>
                        <h2>Admin Report</h2>
                        <div className='file filedatecldr'>
                            <div className='filep'>
                                <span className='datefrmto'>
                                    {StartDate && endDate && (
                                        <p>
                                            {displayStartDate} To {displayEndDate}
                                        </p>
                                    )}
                                </span>
                                <BsCalendar2Date />
                            </div>

                            <div className='bsckreports'>
                                <button><a href={BackbtnLink}>Back</a></button>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ width: '100%', paddingLeft: 10, paddingRight: 10, height: '76vh' }}>
                    <AgGridReact
                        rowData={RespVideoTeammember}
                        filter='text'
                        enableColResize={true}
                        animateRows={true}
                        showToolPanel={true}
                        enableSorting={true}
                        pagination={true}
                        onGridReady={onGridReady}
                        paginationPageSize='10'
                        rowSelection={'multiple'}
                        columnDefs={columnDefsTeamMember}
                        overlayNoRowsTemplate={
                            '<span className="ag-overlay-loading-center">No Data to Show</span>'
                        }
                    >
                    </AgGridReact>
                </div>
                <Footer />
            </div>
        </>
    )
}
